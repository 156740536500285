import React from "react";
import "../App.css";

export default function Copyright() {
  return (
    <React.Fragment>
      <section class="footer-copy">
        <p class="copyright">
          Copyright © 2024 FCBarcelona by Alvi Sports Agency L.C.. All Rights
          Reserved
        </p>
      </section>
    </React.Fragment>
  );
}
