import React from "react";
import "../App.css";

export default function IndexSectionFooters16() {
  return (
    <React.Fragment>
      <section class="footer">
        <div class="container">
          <div class="row">
            <div class="column logo">
              <a href="#">
                <img src="./logo.png" alt="Logo" height="140" width="auto" />
              </a>
            </div>
            <div class="column links">
              <ul>
                <li>
                  <h1>SUPPORT</h1>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/legal-terms">Legal Terms</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
