import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NavMenu from "../Components/NavigationMenuBasic";
import Footer from "../Components/Footer";
import Copyright from "../Components/Copyright";
import "../assets/fonts.css";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function PrivacyPolicy() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <div className="bg-indigo-50">
        <NavMenu />
        <div style={{ padding: "90px", fontFamily: "Interstate, sans-serif" }}>
          <h1
            style={{
              textAlign: "center",
              color: "#0a0a34",
              fontSize: "64px",
              fontWeight: "bold",
            }}
          >
            PRIVACY POLICY
          </h1>

          <h2 style={{ fontFamily: "Interstate, sans-serif", color: "red" }}>
            1. Barça Academy Camps Korea
          </h2>
          <p>
            Barça Academy Camps Korea is located "UBICACION". This Policy
            applies to Barça Academy Camps Korea and each of its related
            companies and subsidiaries (also referred to herein as “we”, “our”
            or “us”).
          </p>

          <h2 style={{ color: "red" }}>2. About this Policy</h2>
          <p>
            This Policy outlines the ways in which Barça Academy Camps Korea
            collects, uses, stores and discloses personal information, explains
            your rights in relation to your personal information, and also
            explains how to contact Barça Academy Camps Korea if desired.
          </p>
          <p>Please read this Policy carefully.</p>

          <h2 style={{ color: "red" }}>3. Personal information</h2>
          <p>
            Personal information may include, but is not limited to, names,
            addresses, phone numbers, email addresses, dates of birth, genders,
            occupations and social media details.
          </p>
          <p>
            Barça Academy Camps Korea may also collect, use, store and disclose
            information such as health records which is considered sensitive
            information.
          </p>

          <h2 style={{ color: "red" }}>4. Summary</h2>
          <p>
            Barça Academy Camps Korea may collect, use, store and disclose
            personal information for a number of purposes, which may include,
            but is not limited to, the provision or promotion of our services.
          </p>
          <p>
            Barça Academy Camps Korea may collect this personal information
            through to the use of our websites, which may include, but is not
            limited to, the use of cookies.
          </p>
          <p>
            This information may be used to optimise or maintain our websites
            and for advertising purposes.
          </p>
          <p>
            Barça Academy Camps Korea takes reasonable steps to protect this
            information.
          </p>
          <p>
            Barça Academy Camps Korea may disclose this information to third
            parties in the course of our operations. We may also disclose this
            information to third parties outside of Korea from time to time.
          </p>
          <p>
            You have a right to access or correct your personal information that
            Barça Academy Camps Korea stores, or to make a complaint about our
            storage of your personal information. You can do so by contacting
            Barça Academy Camps Korea at the address above.
          </p>
          <p>
            You can opt-out of receiving marketing materials from Barça Academy
            Korea at any time by utilising the unsubscribe feature on any
            electronic marketing communications that you receive or by
            contacting Barça Academy Camps Korea at the address above.
          </p>

          <h2 style={{ color: "red" }}>
            5. Collection of personal information
          </h2>
          <p>
            Barça Academy Camps Korea may collect personal information when it
            is provided to Barça Academy Camps Korea by filling in forms,
            contacting Barça Academy Camps Korea, engaging with Barça Academy
            Camps Korea by social media or when subscribing to Barça Academy
            Camps Korea newsletters or other information.
          </p>

          <h2 style={{ color: "red" }}>6. Use of personal information</h2>
          <p>
            Barça Academy Camps Korea may use personal information for purposes
            which may include, but is not limited to, identity verification,
            investigations in the event that applicable laws or regulations are
            breached, including codes of conduct, market research, and
            communication in relation to marketing materials.
          </p>

          <h2 style={{ color: "red" }}>7. Storage of personal information</h2>
          <p>
            Barça Academy Camps Korea may use several physical, administrative,
            personnel and technical measures to protect the security and privacy
            of personal information stored, which may include, but is not
            limited to, the use of password-protected systems.
          </p>
          <p>
            Where Barça Academy Camps Korea has given you, or where you have
            chosen, a password which enables you to access parts of the Barça
            Academy Korea websites, you are responsible for keeping this
            password confidential. Barça Academy Camps Korea suggests that you
            do not share this password with anyone and change it regularly.
          </p>

          <h2 style={{ color: "red" }}>
            8. Disclosure of personal information
          </h2>
          <p>
            Barça Academy Camps Korea may disclose personal information to third
            parties outside of Korea. Barça Academy Camps Korea is not required
            to ensure that third parties outside of Korea use, store and
            disclose this information in accordance with the Privacy Policy.
            However, where practicable, Barça Academy Camps Korea will take
            reasonable steps to do so.
          </p>

          <h2 style={{ color: "red" }}>
            9. Rights in relation to personal information
          </h2>
          <p>
            Barça Academy Camps Korea takes reasonable steps to ensure that the
            personal information that Barça Academy Camps Korea collects, uses,
            stores and discloses is accurate, complete and up to date. However,
            this is dependent upon the information provided.
          </p>
          <p>
            You have a right to access your personal information that Barça
            Academy Camps Korea stores, and to correct this information if it is
            inaccurate, incomplete or out of date. You can do so by contacting
            Barça Academy Camps Korea at the address above.
          </p>

          <h2 style={{ color: "red" }}>10. Opt-out</h2>
          <p>
            You can opt-out of receiving marketing materials from Barça Academy
            Korea at any time by utilising the unsubscribe feature on any
            electronic marketing communications that you receive or by
            contacting Barça Academy Camps Korea at the address above.
          </p>

          <h2 style={{ color: "red" }}>11. Cookies</h2>
          <p>
            When using the Barça Academy Camps Korea websites, we and third
            parties may collect information by using cookies in line with your
            cookie settings.
          </p>
          <p>
            Cookies are small text files that may be placed on your device so
            that Barça Academy Camps Korea can remember your identity and
            preferred settings.
          </p>
          <p>
            Cookies may stay on your device until you leave Barça Academy Camps
            Korea websites or until they expire or are deleted.
          </p>
          <p>
            Browsers may be configured to accept or reject cookies and notify
            you when cookies are sent. Each browser is different, so please
            refer to the “help” menu of your browser to configure cookies
            preferences.
          </p>
          <p>
            Cookies can be disabled entirely. However, doing so may limit access
            to Barça Academy Camps Korea websites’ contents and functions.
          </p>

          <h2 style={{ color: "red" }}>12. Updates</h2>
          <p>
            We may update this Policy from time to time. This Policy was last
            updated on 10 September 2024.
          </p>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
      <div className="footer-copy">
        <Copyright />
      </div>
    </React.Fragment>
  );
}
