import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import InicioPage from "./pages/Inicio";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LegalTerms from "./pages/LegalTerms";
import Contact from "./pages/Contact";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<InicioPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/legal-terms" element={<LegalTerms />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
