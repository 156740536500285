import React from "react";
import "../App.css";

const NavigationMenuBasic = ({ onLanguageChange }) => {
  return (
    <nav
      className="NavBlue mb-5 mt-5 p-4"
      style={{
        opacity: "90%",
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: "1000",
        height: "100px",
        borderBottom: "3px solid #e2ac2e",
      }}
    >
      <img src="./logo.png" alt="Logo" className="NavLogo logo-pers" />
      <div
        className="language-switch"
        style={{
          marginRight: "20px",
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          display: "flex",
          alignItems: "center",
        }}
      ></div>
    </nav>
  );
};

export default NavigationMenuBasic;
