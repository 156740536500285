import React from "react";
import "../App.css";

const HeaderMap = ({ language }) => {
  const mapSrc =
    language === "ENG"
      ? "./BarçaAcademyMapaEmpty.png"
      : "../BarçaAcademyMapaEmpty.png";

  return (
    <img
      //src={mapSrc}
      //alt={`Mapa en ${language}`}
      src="./Poster_2024.png"
      style={{ paddingTop: "90px" }}
      class="full-width-img mx-auto h-auto"
    />
  );
};

export default HeaderMap;
