import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NavMenu from "../Components/NavigationMenuBasic";
import Footer from "../Components/Footer";
import Copyright from "../Components/Copyright";
import "../assets/fonts.css";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function LegalTerms() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <div className="bg-indigo-50">
        <NavMenu />
        <div
          style={{
            padding: "90px",
            fontFamily: "Interstate, Arial, sans-serif",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              color: "#0a0a34",
              fontSize: "64px",
              fontWeight: "bold",
            }}
          >
            LEGAL TERMS
          </h1>

          <h2
            style={{
              color: "red",
              fontFamily: "Interstate, Arial, sans-serif",
            }}
          >
            1. Behaviour/Conduct Policy
          </h2>
          <p>
            FCB Academy Camps Korea reserves the right at its absolute
            discretion to refuse applications for its football program or school
            holiday football camp.
          </p>
          <p>
            FCB Academy Camps Korea reserves the right at its absolute
            discretion to exclude or remove abusive or disruptive participants
            from its football program or school holiday football camp.
          </p>
          <p>
            All participants in a FCB Academy Camps Korea football program or
            school holiday football camp are responsible for their own behaviour
            and conduct. Any physical or verbal abuse whatsoever will be treated
            with zero tolerance and will result in the immediate removal of the
            participant from a FCB Academy Camps Korea football program or
            school holiday football camp.
          </p>
          <p>
            Parents or Guardians must notify FCB Academy Camps Korea or its
            staff if they wish to remove their child from any session.
          </p>
          <p>
            All participants in a FCB Academy Camps Korea football program or
            school holiday football camp are responsible for complying with the
            course timetable.
          </p>
          <p>
            FCB Academy Camps Korea will not provide any refunds or credit for
            any session, or part thereof missed, as a consequence of lateness,
            absence or removal.
          </p>
          <p>
            All participants in a FCB Academy Camps Korea football program or
            school holiday football camp must be self-sufficient and will be
            expected to use the bathroom and eat and drink on their own and
            apply sunscreen themselves when reminded by FCB Academy Camps Korea
            or its staff.
          </p>
          <p>
            All participants in a FCB Academy Camps Korea football program or
            school holiday football camp must purchase and wear the required FCB
            Academy Camps Korea uniform at all times, as well as appropriate
            footwear (football boots or sneakers depending on the session) and
            shinpads.
          </p>

          <h2
            style={{
              color: "red",
              fontFamily: "Interstate, Arial, sans-serif",
            }}
          >
            2. Liability
          </h2>
          <p>
            FCB Academy Camps Korea accepts no responsibility for any loss of,
            or damage to, personal property whilst attending, or participating
            in, any FCB Academy Camps Korea football program or school holiday
            football camp.
          </p>
          <p>
            FCB Academy Camps Korea and its staff accept no responsibility for
            any injury, death, loss or damage to any person occurring out of, or
            in relation to, any FCB Academy Camps Korea football program or
            school holiday football camp.
          </p>
          <p>
            Parents and Guardians are aware of, and fully understand, the risks
            associated with their children participating in a FCB Academy Camps
            Korea football program or school holiday football camp and
            acknowledge that their children participate at their own risk and
            shall indemnify FCB Academy Camps Korea and its staff against all
            actions, suits, proceedings, demands, costs and expenses which may
            be taken or made against FCB Academy Camps Korea.
          </p>
          <p>
            If a Parent or Guardian of a participant is unable to be contacted,
            or it is otherwise impractical for the Parent or Guardian to be
            contacted, FCB Academy Camps Korea and its staff are authorised to:
          </p>
          <ul style={{ textAlign: "left", display: "inline-block" }}>
            <li>
              Consent to a participant receiving medical or dental treatment
              from a medical practitioner in the event of an illness or
              accident.
            </li>
            <li>
              Administer or consent to such first aid as FCB Academy Camps Korea
              and its staff may consider to be reasonably necessary in the case
              of illness or injury.
            </li>
          </ul>
          <p>
            Parents and Guardians acknowledge and accept all risk involved in
            the administration of any medical or dental treatment or first aid
            considered to be reasonably necessary and the responsibility of
            payments of all expenses incurred in relation to such treatment or
            aid including, but not limited to, any emergency transportation
            required.
          </p>
          <p>
            Parents and guardians must disclose to FCB Academy Camps Korea any
            medical condition that their children participating in a FCB Academy
            Camps Korea football program or school holiday football camp have on
            the application form. This information will be kept strictly
            confidential by FCB Academy Camps Korea and its staff unless
            required to be disclosed for treatment or aid purposes.
          </p>

          <h2
            style={{
              color: "red",
              fontFamily: "Interstate, Arial, sans-serif",
            }}
          >
            3. Weather Conditions/Timetable Changes
          </h2>
          <p>
            Whilst FCB Academy Camps Korea endeavours to operate its football
            program or school holiday football camp in accordance with the
            course timetable, it reserves the right at its absolute discretion
            to make changes to the course timetable, including but not limited
            to, venue, dates and times.
          </p>
          <p>
            FCB Academy Camps Korea and its staff will take appropriate action
            at its absolute discretion to protect participant welfare in the
            event of extreme weather conditions.
          </p>
          <p>
            If required, FCB Academy Camps Korea and its staff will contact
            Parents or Guardians to collect their children.
          </p>
          <p>
            FCB Academy Camps Korea will not provide refunds or credit for any
            session, or part thereof missed, as a consequence of extreme weather
            conditions which prevent FCB Academy Camps Korea from continuing or
            altering any session or the course timetable generally.
          </p>

          <h2
            style={{
              color: "red",
              fontFamily: "Interstate, Arial, sans-serif",
            }}
          >
            4. Food and Drink
          </h2>
          <p>
            All participants in a FCB Academy Camps Korea football program or
            school holiday football camp must provide their own food and drink.
            FCB Academy Camps Korea has a strict no-nut policy. All sessions
            will include breaks for participants to eat and drink.
          </p>

          <h2
            style={{
              color: "red",
              fontFamily: "Interstate, Arial, sans-serif",
            }}
          >
            5. Cancellation/Refund Policy
          </h2>
          <p>
            Parents and Guardians acknowledge that by enrolling in a FCB Academy
            Korea football program or school holiday football camp they are
            committing to a service contract.
          </p>
          <p>
            FCB Academy Camps Korea football programs or school holiday football
            camps are subject to change or cancellation.
          </p>
          <p>
            FCB Academy Camps Korea will not provide refunds or credit unless it
            cancels a football program or school holiday football camp as a
            result of its staff being unable to travel to Korea. Liability is
            limited to a full refund of the price for the football program or
            school holiday football camp and does not include any costs for
            loss, delay, inconvenience, disappointment, travel or other expense
            whatsoever.
          </p>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
      <div className="footer-copy">
        <Copyright />
      </div>
    </React.Fragment>
  );
}
