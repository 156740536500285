import React from "react";
import "../App.css";
import "../assets/fonts.css";

const Info = ({ language }) => {
  const handleMoreInfoClick = () => {
    // Redirige a la URL
    window.location.href = "https://barcaacademy.fcbarcelona.com/en/";
  };
  return (
    <div style={{ textAlign: "center", paddingTop: "90px" }}>
      <button
        onClick={handleMoreInfoClick}
        style={{
          marginBottom: "84px", // Margen inferior
          padding: "30px 60px", // Botón más grande
          fontSize: "28px",
          fontFamily: "Interstate", // Aplicar la fuente personalizada
          fontWeight: "bold",
          backgroundColor: "#e2ac2e", // Color de fondo personalizado
          color: "#0a0a34", // Texto blanco
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        More Info
      </button>
    </div>
  );
};

export default Info;
