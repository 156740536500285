import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NavMenu from "../Components/NavigationMenuBasic";
import Footer from "../Components/Footer";
import Copyright from "../Components/Copyright";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Contact() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <div className="App">
        <NavMenu />
        <img
          src="./Poster_2024.png"
          alt={`Contacto`}
          style={{ paddingTop: "90px" }}
          class="full-width-img mx-auto h-auto"
        />
      </div>
      <div className="footer">
        <Footer />
      </div>
      <div className="footer-copy">
        <Copyright />
      </div>
    </React.Fragment>
  );
}
